<template>
  <div>
    <title-header :title="title" :buttons="headerButtons"></title-header>

  <v-row class="mr-5">
    <v-spacer></v-spacer>
    <v-col justify="end" md="4">
      <v-text-field
        v-model="search"
        class="ml-5"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        clearable
        hide-details>
      </v-text-field>
    </v-col>
  </v-row>

    <v-data-table
      :headers="headers"
      :items="groups"
      :items-per-page="20"
      :search="search"
      class="elevation-1 pl-10 pr-10"
    >
      <template v-slot:item.buttons="{ item }">
        <v-row justify="end">
          <v-btn
            class="mx-2"
            rounded
            outlined
            small
            color="primary"
            @click="routeToEditGroup(item)"
          >Editar</v-btn>
          <v-btn
            class="mx-2"
            rounded
            outlined
            color="red"
            small
            @click="removeGroupDialog(item)"
          >Eliminar</v-btn>
        </v-row>
        <v-row justify="end"></v-row>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Eliminar {{selectedItem.name}}</v-card-title>
      <v-card-text>¿Seguro que deseas eliminar este grupo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="removeGroupClicked(selectedItem)">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import { GROUPS_GET_GROUPS, GROUPS_REMOVE_GROUP } from '@/store/actions.type'

export default {
  name: 'group-list',
  props: {},
  data() {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: '', value: 'buttons' }
      ],
      title: 'Grupos',
      selectedItem: {},
      snackBarText: '',
      search: '',
      showSnackBar: false,
      dialog: false,
      headerButtons: [
        {
          text: 'Nuevo grupo',
          urlSlug: 'groups/create'
        }
        /* ,{
          text: 'Importar grupos',
          urlSlug: 'groups/import'
        } */
      ]
    }
  },
  computed: {
    ...mapGetters({
      groups: 'getAllGroups'
    })
  },
  methods: {
    ...mapActions({
      getGroups: GROUPS_GET_GROUPS,
      removeGroup: GROUPS_REMOVE_GROUP
    }),
    removeGroupDialog(item) {
      this.selectedItem = item
      this.dialog = true
    },
    removeGroupClicked(group) {
      this.removeGroup(group)
        .then(response => {
          this.showSnackBar = true
          this.snackBarText = 'Se elimino el grupo ' + this.selectedItem.name
          this.dialog = false
          this.selectedItem = {}
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackBar = true
        })
    },
    routeToEditGroup(group) {
      this.$router.push('groups/' + group.id)
    }
  },
  components: {
    TitleHeader
  },
  created() {
    this.getGroups()
  }
}
</script>

<style>
</style>
